





































































































































































































































































































































































































































































.zsBtn {
  margin-left: 20px;
  button {
    height: 30px;
  }
}
.iconStyle {
  font-size: 2rem;
  color: #6676ff;
  cursor: pointer;
}
